html {
  font-size: 10px;
}
#zmmtg-root {
  display: none;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .lane-border-color {
    border-right-color: #E6E6E6;
    border-top-color: #E6E6E6;
    border-bottom-color: #E6E6E6;
    border-left-color: #E6E6E6;
  }
}

.animation-pulse {
  box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
  transform: scale(0.8);
  animation: pulse 5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255,0,0, 0.7);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 5px rgba(255,0,0, 0);
  }

  100% {
    transform: scale(1);
  }
}
